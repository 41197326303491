import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import arrow from "../../images/hire-penetration-tester/arrow.png"
import cir from "../../images/hire-penetration-tester/cir.png"
import dots from "../../images/hire-penetration-tester/dots.png"
import Frame from "../../images/hire-penetration-tester/Frame.png"
import wellBox from "../../images/hire-penetration-tester/wellBox.png"
import wellRec from "../../images/hire-penetration-tester/wellRec2.png"
import * as styles from "./WellDefend.module.scss"
import "./WellDefend.scss"

const WellDefend = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 2 ||
      current_1 === strapiData?.cards.length - 1 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 5000)
  }, [current, current_1, current_2])

  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dotsClass: "new-dot-python",
    adaptiveHeight: true,
    arrows: false,
    infinite: false,
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 768,
        arrows: false,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <section className={styles.wellDefend}>
      <img
        className={styles.wellBox}
        src={wellBox}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.arrow}
        src={arrow}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.frame}
        src={Frame}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <Container>
        <h2
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.subHeading}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <img
          className={styles.dots}
          src={dots}
          decoding="async"
          loading="lazy"
          alt="background"
        />
        <div className="my-5">
          <Slider {...settings} className="" ref={slide1}>
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <div className={`${styles.cardWrapper} my-5`} key={index.id}>
                  <div className={styles.icon}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={item?.image1[0]?.localFile?.publicURL}
                      alt={item.title}
                    />
                  </div>
                  <div className={styles.data}>
                    <h3 className={styles.head}>{item.title}</h3>
                    <div
                      className={styles.desc}
                      dangerouslySetInnerHTML={{
                        __html: item?.description?.description,
                      }}
                    />
                  </div>
                </div>
              ))}
          </Slider>
          <img
            className={styles.dots2}
            src={dots}
            decoding="async"
            loading="lazy"
            alt="background"
          />
        </div>
        <div className="pt-4 text-center">
          <Link to={strapiData?.buttons[0]?.url} className="btn_black_border">
            {strapiData?.buttons[0]?.title}
          </Link>
        </div>
      </Container>
      <img
        className={styles.wellRec}
        src={wellRec}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.cir}
        src={cir}
        decoding="async"
        loading="lazy"
        alt="background"
      />
    </section>
  )
}

export default WellDefend
