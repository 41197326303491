import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Dots from "../../images/hire-penetration-tester/dots.svg"
import Fill from "../../images/hire-penetration-tester/filltriangle.svg"
import Circle from "../../images/hire-penetration-tester/halfcircle.svg"
import Square from "../../images/hire-penetration-tester/halfsquare.svg"
import Unfill from "../../images/hire-penetration-tester/nonefill.svg"
import * as styles from "./Collaboration.module.scss"

const Collaboration = ({ strapiData }) => {
  return (
    <section className={styles.collaboration}>
      <img
        className={styles.circle}
        src={Circle}
        alt="absolute-icon"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.nonFill}
        src={Unfill}
        alt="absolute-icon"
        decoding="async"
        loading="lazy"
      />
      <Container>
        <h2
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.subHeading}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />

        <div className="my-3">
          <Row className="px-xl-5 justify-content-center">
            <img
              className={styles.dots}
              src={Dots}
              alt="absolute-icon"
              decoding="async"
              loading="lazy"
            />
            <img
              className={styles.dots2}
              src={Dots}
              alt="absolute-icon"
              decoding="async"
              loading="lazy"
            />
            {strapiData?.cards &&
              strapiData?.cards?.map((item, index) => (
                <Col lg={6} className="position-relative">
                  <div
                    className={`${styles.cardWrapper} ${
                      index === 1
                        ? styles.index3
                        : index === 2
                        ? styles.index3
                        : ""
                    } my-5`}
                  >
                    <div>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.image1[0]?.localFile?.publicURL}
                        alt="icons"
                      />
                    </div>
                    <div className={styles.data}>
                      <h3 className={`${styles.head} pb-3`}>{item.title}</h3>

                      <p
                        className={styles.desc}
                        dangerouslySetInnerHTML={{
                          __html: item?.description?.description,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
      <img
        className={styles.halfSquare}
        src={Square}
        alt="absolute-icon"
        decoding="async"
        loading="lazy"
      />
      <img
        className={styles.fillTriangle}
        src={Fill}
        alt="absolute-icon"
        decoding="async"
        loading="lazy"
      />
    </section>
  )
}

export default Collaboration
