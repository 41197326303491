// extracted by mini-css-extract-plugin
export var arrow = "WellDefend-module--arrow--81239";
export var cardWrapper = "WellDefend-module--cardWrapper--a2d10";
export var cir = "WellDefend-module--cir--79ab9";
export var data = "WellDefend-module--data--c7fa1";
export var desc = "WellDefend-module--desc--fd1ac";
export var dots = "WellDefend-module--dots--20c2e";
export var dots2 = "WellDefend-module--dots2--9e4c4";
export var frame = "WellDefend-module--frame--d8c6a";
export var head = "WellDefend-module--head--f7f73";
export var icon = "WellDefend-module--icon--4a7ed";
export var subHeading = "WellDefend-module--subHeading--7a104";
export var wellBox = "WellDefend-module--wellBox--fb485";
export var wellDefend = "WellDefend-module--wellDefend--94988";
export var wellRec = "WellDefend-module--wellRec--f4837";