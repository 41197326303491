import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import arow from "../../images/hire-penetration-tester/arow.png"
import cir from "../../images/hire-penetration-tester/cir.png"
import dots from "../../images/hire-penetration-tester/dots.png"
import Frame from "../../images/hire-penetration-tester/Frame.png"
import rubustRec from "../../images/hire-penetration-tester/rubustRec.png"
import wellBox from "../../images/hire-penetration-tester/wellBox.png"
import * as styles from "./Robust.module.scss"

const Robust = ({ strapiData }) => {
  return (
    <section className={styles.robust}>
      <img
        className={styles.cir}
        src={cir}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.rubustRec}
        src={rubustRec}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.wellBox}
        src={wellBox}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.dots}
        src={dots}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <Container>
        <img
          className={styles.arow}
          src={arow}
          decoding="async"
          loading="lazy"
          alt="background"
        />
        <h2
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.subHeading}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className="my-3">
          <Row className="px-xl-5 py-5 justify-content-center">
            <img
              src={strapiData?.secImages[0]?.localFile?.publicURL}
              alt="robust"
              width="100%"
              height="auto"
              decoding="async"
              loading="lazy"
            />
          </Row>
        </div>
      </Container>
      <img
        className={styles.Frame}
        src={Frame}
        decoding="async"
        loading="lazy"
        alt="background"
      />
      <img
        className={styles.dots2}
        src={dots}
        decoding="async"
        loading="lazy"
        alt="background"
      />
    </section>
  )
}

export default Robust
