import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./CyberThreat.module.scss"

const CyberThreat = ({ strapiData }) => {
  return (
    <section className={styles.cyberThreat}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.desc}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <div className="pt-5 mb-5 text-center">
          <Link to={strapiData?.buttons[0]?.url} className="btn_white1_border">
            {strapiData?.buttons[0]?.title}
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default CyberThreat
