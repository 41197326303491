import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TopTier.module.scss"

const TopTier = ({ strapiData }) => {
  return (
    <section className={styles.topTier}>
      <Container>
        <h2
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.subHeading}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />

        <div className={styles.flexWrapper}>
          {strapiData?.cards &&
            strapiData?.cards?.map((item, index) => (
              <div
                className={`${styles.card} ${
                  index === 0
                    ? styles.index2
                    : index === 2
                    ? styles.index2
                    : index === 4
                    ? styles.index2
                    : ""
                } `}
              >
                <div
                  className={` ${styles.coloredBox} ${
                    index === 0
                      ? styles.skyBlue
                      : index === 1
                      ? styles.orange
                      : index === 2
                      ? styles.pink
                      : index === 3
                      ? styles.green
                      : index === 4
                      ? styles.grey
                      : ""
                  } `}
                >
                  <div className={`${styles.pHead}`}>0{index + 1}</div>
                  <h3 className={`${styles.sHead}`}>{item.title}</h3>
                  <div className={`${styles.Head}`}>{item.subTitle}</div>
                </div>
              </div>
            ))}
        </div>
        <div className="pt-5 mt-5 text-center">
          <Link to={"/"} className="btn_black_border">
            Secure My Enterprise Network
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default TopTier
