import { graphql } from "gatsby"
import React from "react"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/hire-penetration-tester/Banner"
import Collaboration from "../components/hire-penetration-tester/Collaboration"
import CyberThreat from "../components/hire-penetration-tester/CyberThreat"
import Robust from "../components/hire-penetration-tester/Robust"
import TechStack from "../components/hire-penetration-tester/TechStack"
import TopTier from "../components/hire-penetration-tester/TopTier"
import WellDefend from "../components/hire-penetration-tester/WellDefend"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_penetration_testing_consultants_0b616da39d.webp"
    />
  )
}
const Node = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const wellgournd = data?.strapiPage?.sections[1]
  const collaboration = data?.strapiPage?.sections[2]
  const rubust = data?.strapiPage?.sections[3]
  const customizedExp = data?.strapiPage?.sections[4]
  const toptier = data?.strapiPage?.sections[5]
  const techstack = data?.strapiPage?.sections[6]
  const cyberthreat = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={banner} />
      <WellDefend strapiData={wellgournd} />
      <Collaboration strapiData={collaboration} />
      <Robust strapiData={rubust} />
      <EngagementModels strapiData={customizedExp} />
      <TopTier strapiData={toptier} />
      <TechStack strapiData={techstack} />
      <CyberThreat strapiData={cyberthreat} />
      <Faqs strapiData={faqs} whiteBg={true} />
    </MainLayout>
  )
}

export const query = graphql`
  query tester {
    strapiPage(slug: { eq: "hire-penetration-testing-consultants" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default Node
