// extracted by mini-css-extract-plugin
export var cardWrapper = "Collaboration-module--cardWrapper--a4fd7";
export var circle = "Collaboration-module--circle--e1e00";
export var collaboration = "Collaboration-module--collaboration--cca78";
export var data = "Collaboration-module--data--b5c2e";
export var desc = "Collaboration-module--desc--c00e2";
export var dots = "Collaboration-module--dots--063cf";
export var dots2 = "Collaboration-module--dots2--f0af1";
export var fillTriangle = "Collaboration-module--fillTriangle--44bc9";
export var halfSquare = "Collaboration-module--halfSquare--572de";
export var head = "Collaboration-module--head--a1f3f";
export var index3 = "Collaboration-module--index3--dec08";
export var nonFill = "Collaboration-module--nonFill--510b5";
export var subHeading = "Collaboration-module--subHeading--b34ec";